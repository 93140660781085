import { useContext } from "react";
import { AppContext } from "../../App";
import axios from "axios";
import { Button, CssBaseline, CssVarsProvider, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Select, Option, Stack, Alert, ModalClose } from "@mui/joy";
import { Project, Termin, createTermin } from "../../types";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as extendMaterialTheme,
    THEME_ID
} from "@mui/material/styles";
import InfoSnackbar from "../InfoSnackbar";
import { format } from "path";

const materialTheme = extendMaterialTheme();

dayjs.extend(customParseFormat)


export function EditTermin(props: { termin: Termin, setTermin: React.Dispatch<React.SetStateAction<Termin>>, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, snackbarConfig: (snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) => void }) {
    const token = useContext(AppContext).token;


    // a copy of termin data, so that it gets updated after submitting
    const [terminCopy, setTerminCopy] = React.useState<Termin>({
      id: props.termin.id,
      project: props.termin.project,
      date: props.termin.date,
      start: props.termin.start,
      end: props.termin.end,
      location: props.termin.location,
      termin_type: props.termin.termin_type,
    });

    // loading state
    const [loading, setLoading] = React.useState<boolean>(false);

    // States for the Create termin function
    const [date, setDate] = React.useState<Dayjs | null>(dayjs(props.termin.date, 'DD.MM.YYYY'));
    const [start, setStart] = React.useState<Dayjs | null>(dayjs(props.termin.start.slice(0,5), 'HH:mm'));
    const [end, setEnd] = React.useState<Dayjs | null>(dayjs(props.termin.end.slice(0,5), 'HH:mm'));
    const [location, setLocation] = React.useState<string>(props.termin.location);
    const [type, setType] = React.useState<string>(props.termin.termin_type);


    // string values to pass to the json data with the request - above objects might be null
    const [stringDate, setStringDate] = React.useState<string>("DD.MM.YYYY");
    const [stringStart, setStringStart] = React.useState<string>("HH:mm");
    const [stringEnd, setStringEnd] = React.useState<string>("HH:mm");

    React.useEffect(() => {
        date != null ? setStringDate(date.format("DD.MM.YYYY")) : setStringDate("null");
        start != null ? setStringStart(start.format("HH:mm:ss")) : setStringStart("null");
        end != null ? setStringEnd(end.format("HH:mm:ss")) : setStringEnd("null");
    }, [date, start, end]);


    // states for the snackbar
    const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
    const [snackbarType, setSnackbarType] = React.useState<"danger" | "neutral" | "primary" | "success" | "warning">("primary");
    const [snackbarInfo, setSnackbarInfo] = React.useState<string>("");

    const open = React.useRef<boolean>(false);
    function setOpen(value: boolean) {
        open.current = value;
    }
    // Make a POST request to the API to edit an existing termin

    async function editTermin(newTermin: Termin) {
        setLoading(true);
        setSnackbarOpen(true)
        if (newTermin.id != props.termin.id) {
            alert("IDs do not match")
            return;
        }
        if (newTermin.project != props.termin.project) {
            alert("Project IDs do not match")
            return;
        }
        await axios.patch(`https://hudniki.pythonanywhere.com/endpoints/termins/${newTermin.id}/`, {
            'project': newTermin.project,
            'termin_type': newTermin.termin_type,
            'date': date?.format("YYYY-MM-DD"),
            'start': newTermin.start,
            'end': newTermin.end,
            'location': newTermin.location
        }, {
            headers: {
                'Authorization': `Token ${token}`
            }

        }).then(
            (response) => { console.log(response) 
                setLoading(false);
                // props.setOpen(false);
                // setSnackbarType("success");
                // setSnackbarInfo("Termin uspešno spremenjen");
                props.snackbarConfig("success", "Termin uspešno posobodljen")
            }
        ).catch(
            (error) => { console.log(error) 
                setLoading(false);
                props.setOpen(false);
                // setSnackbarType("danger");
                // setSnackbarInfo("Napaka pri spreminjanju termina");
                props.snackbarConfig("danger", "Napaka pri posodabljanju termina: " + error)
            }
        );
        setSnackbarOpen(true);

    }


    return (
        <>
            <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
                <CssVarsProvider>
                    <CssBaseline />
                    <Modal
                        open={props.open}
                        onClose={() => { props.setOpen(false) }}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <ModalDialog>
                            <ModalClose />
                            <DialogTitle>Dodaj termin</DialogTitle>
                            <DialogContent>Izpolni vse podatke</DialogContent>
                            <form
                                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                    event.preventDefault();
                                    let newTermin = createTermin(props.termin.id, props.termin.project, type, stringDate, stringStart, stringEnd, location)
                                    props.setTermin(newTermin)
                                    console.log("setting the date to: " + date?.toString())
                                    setTerminCopy(newTermin)
                                    editTermin(newTermin)
                                }}
                            >
                                <Stack spacing={2}>
                                    <FormControl>
                                        <Select
                                            placeholder="Izberi tip termina"
                                            defaultValue={type}
                                            onChange={(
                                                event: React.SyntheticEvent | null,
                                                newValue: string | null,) => {
                                                newValue != null ? setType(newValue) : setType("V");
                                            }}>
                                            <Option value="V">Vaja</Option>
                                            <Option value="N">Nastop</Option>
                                            <Option value="K">Koncert</Option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    label="Datum"
                                                    defaultValue={date}
                                                    onChange={(newValue) => {
                                                        setDate(dayjs(newValue, 'DD.MM.YYYY'))
                                                    }}
                                                    format="DD.MM.YYYY"

                                                    />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimeField
                                                label="Začetek"
                                                defaultValue={start}
                                                onChange={(newValue) => setStart((start) => {
                                                    if (start != null && newValue != null) {
                                                        start = start.set('hour', newValue.hour());
                                                        start = start.set('minute', newValue.minute());
                                                        return start
                                                    }
                                                    return start; // Add a default return value
                                                })}
                                                format="HH:mm"
                                            />
                                        </LocalizationProvider>

                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <TimeField
                                                label="Konec"
                                                defaultValue={end}
                                                onChange={(newValue) => setEnd((end) => {
                                                    if (end != null && newValue != null) {
                                                        end = end.set('hour', newValue.hour());
                                                        end = end.set('minute', newValue.minute());
                                                        return end
                                                    }
                                                    console.log(end)

                                                    return end; // Add a default return value
                                                })}
                                                format="HH:mm"
                                            />
                                        </LocalizationProvider>

                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Lokacija</FormLabel>
                                        <Input defaultValue={location} required onChange={(event) => { setLocation(event.target.value) }} />
                                    </FormControl>
                                    <Button type="submit" loading={loading}>Posodobi</Button>
                                </Stack>
                            </form>
                        </ModalDialog>
                    </Modal>
                </CssVarsProvider>
            </MaterialCssVarsProvider>
            <InfoSnackbar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarInfo={snackbarInfo} snackbarType={snackbarType} />

        </>
    );
}
