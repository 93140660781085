import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Snackbar } from '@mui/joy';
import { createContext } from 'react';
import { Add } from '@mui/icons-material';

import axios from 'axios';

import Sidebar from './components/Sidebar';
import OrderTable from './components/OrderTable';
import OrderList from './components/OrderList';
import Header from './components/Header';
import { AddTermin } from './components/modals/AddTermin';
import { Project } from './types';
import Login from './components/Login';
import Home from './Home';


export const AppContext = createContext<{token: string | null, setToken: (token: string | null) => void, user: string | null, setUser: (user: string | null) => void}>({
    token: localStorage.getItem("token"),
    setToken: function (token: string | null) {
        this.token = token;
    },
    user: localStorage.getItem("user"),
    setUser: function (user: string | null) {
        this.user = user;
    }
});
// export const TokenContext = createContext<string | null>(null);

export default function App() {
    // const [token, setToken] = React.useState<string | null>(null);
    // const [user, setUser] = React.useState<string | null>(null);

    // Current project
    const [currentProject, setCurrentProject] = React.useState<Project>({ id: 1, name: "Julij 2024", end_date: "2024-07-31" });

    // Snackbar states to display error messages, confirmation messages, etc.
    const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
    const [snackbarType, setSnackbarType] = React.useState<"danger" | "neutral" | "primary" | "success" | "warning">("primary");
    const [snackbarInfo, setSnackbarInfo] = React.useState<string>("");

    function snackbarConfig(snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) {
        setSnackbarOpen(true);
        setSnackbarType(snackbarType);
        setSnackbarInfo(snackbarInfo);
    }

    // states for the AddTermin Modal component
    const [openAddTermin, setOpenAddTermin] = React.useState<boolean>(false);

    // Make a POST request to the API to authenticate the user

    // React.useEffect(() => {
    //   axios.post('https://hudniki.pythonanywhere.com/auth/token/login', {
    //     "password": "uL*GbeD$hX9p9",
    //     "username": "hudniki"
    //   }).then(response => {
    //     setToken(response.data.auth_token);
    //     snackbarConfig('success', 'Successfully logged in user.');
    //   }).catch(error => {
    //     snackbarConfig('danger', 'Error: ' + error);
    //   })
    // }, []);

    // if (token !== null) {
    return (
        <>
            <AppContext.Provider value={{
                token: localStorage.getItem("token"),
                setToken: function (token: string | null) {
                    this.token = token;
                    },
                user: localStorage.getItem("user"),
                setUser: function (user: string | null) {
                    this.user = user;
                }
            }}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/">
                                <Route index element={<Home />} />
                                <Route path="login" element={<Login />} />
                                {/* <Route path="contact" element={<Contact />} />
                    <Route path="*" element={<NoPage />} /> */}
                            </Route>
                        </Routes>
                    </BrowserRouter>
            </AppContext.Provider>
        </>

    )
}
