import { Snackbar } from "@mui/joy"
import React from "react";

export default function InfoSnackbar(props: { snackbarOpen: boolean, setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>, snackbarType: "danger" | "neutral" | "primary" | "success" | "warning", snackbarInfo: string }) {
    const [open, setOpen] = React.useState<boolean>(props.snackbarOpen);
    const [info, setInfo] = React.useState<string>("");
    const [type, setType] = React.useState<"danger" | "neutral" | "primary" | "success" | "warning">("primary");
    
    React.useEffect(() => {
        setInfo(props.snackbarInfo);
    }, [props.snackbarInfo])

    React.useEffect(() => {
        // console.log('props.snackbarOpen changed:', props.snackbarOpen);
        setOpen(props.snackbarOpen);
    }, [props.snackbarOpen]) 

    React.useEffect(() => {
        setType(props.snackbarType);
    }, [props.snackbarType])

    return (
        <div key={props.snackbarInfo}>
            <Snackbar
                autoHideDuration={3000}
                open={open}
                color={type}
                onClose={() => {
                    // props.setSnackbarOpen(false)
                    setInfo("")
                }}>
                {info}
            </Snackbar>
        </div>
    )
}