export interface Termin {
    id: number;
    project: number;
    termin_type: string;
    date: string;
    start: string;
    end: string;
    location: string;
}

export function createTermin (id: number, project: number, termin_type: string, date: string, start: string, end: string, location: string): Termin {
    return {
        id: id,
        project: project,
        termin_type: termin_type,
        date: date,
        start: start,
        end: end,
        location: location
    }
}

function getStart(termin: Termin) : Date {
    let start = new Date()
    const date = termin.date.split(".")
    start.setFullYear(Number(date[2]), Number(date[1]) - 1, Number(date[0]))
    const time = termin.start.split(":")
    start.setHours(Number(time[0]), Number(time[1]))
    return start
}

// a function that sorts termins by date and time
export function sortTermins(termins: Termin[], descending: boolean = false) {
    let i=0
    descending ? i = -1 : i = 1
    termins.sort((a, b) => {
        let a_date = getStart(a)
        let b_date = getStart(b)
        return (a_date.getTime() - b_date.getTime())
    })
    if (descending) {
        termins.sort((a, b) => {
            let a_date = getStart(a)
            let b_date = getStart(b)
            return (a_date.getDate() - b_date.getDate()) * i
        }) 
    }
    return termins

}


export interface Project {
    id: number;
    name: string;
    end_date: string;
    // description: string;
    // termins: Termin[];
}

export function createProject (id: number, name: string, end_date: string): Project {
    return {
        id: id,
        name: name,
        end_date: end_date,
    }
}

export function sortProjects(projects: Project[]) {
    projects.sort((a, b) => {
        let a_date = new Date(a.end_date)
        let b_date = new Date(b.end_date)
        return (a_date.getTime() - b_date.getTime())
    })
    return projects
}