import * as React from "react";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { Card, CssVarsProvider, IconButton, useColorScheme } from "@mui/joy";
import axios from "axios";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import LoginError from "./LoginError";
import { redirect } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { AppContext } from "../App";

axios.defaults.withCredentials = true;


interface FormElements extends HTMLFormControlsCollection {
    username: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

export default function Login(props: {
    // setToken: React.Dispatch<React.SetStateAction<string | null>>;
    // setUser: React.Dispatch<React.SetStateAction<string | null>>;
    // snackBarConfig: Function;
    // setIsGuest: React.Dispatch<React.SetStateAction<boolean>>;
    // setUserEmail: React.Dispatch<React.SetStateAction<String>>;
}) {
    const context = React.useContext(AppContext);

    const navigate = useNavigate();

    const [loginError, setLoginError] = React.useState(false);
    const { mode, setMode } = useColorScheme();

    async function login(username: string, password: string) {
        // React.useEffect(() => {
            await axios.post('https://hudniki.pythonanywhere.com/auth/token/login', {
                "password": password,
                "username": username
            }, {
                headers: {
                'Access-Control-Allow-Credentials': 'true',
                // 'Access-Control-Allow-Origin': '*'
            }}).then(response => {
                context.setToken(response.data.auth_token);
                // props.snackbarConfig('success', 'Successfully logged in user.');
                // console.log(token)
                context.setUser(username);
                localStorage.setItem("token", response.data.auth_token);
                localStorage.setItem("user", username);
                navigate('/');
            }).catch(error => {
                console.log(error)
                // props.snackbarConfig('danger', 'Error: ' + error);
            })
        // }, []);
    }

    return (
        // <CssVarsProvider defaultMode="dark">
        <>
            <CssVarsProvider >
            <CssBaseline />

            <Card
                sx={{
                    display: "flex",
                    maxWidth: "800px",
                    justifyContent: "center",
                    mx: "auto",
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 6 },
                }}
            >
                <Box sx={{ mb: 1, mt: 3 }}>

                    <Box
                        sx={{
                            position: "absolute",
                            top: "0.875rem",
                            left: "1rem",
                        }}
                    >
                        <IconButton onClick={() => setMode(mode === "light" ? "dark" : "light")}>
                            {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                        </IconButton>
                    </Box>

                    <Typography level="title-lg">GGS-ADMIN</Typography>
                    <Typography level="body-sm">
                        Aplikacija za organizacijo projektov Glasba gre v svet
                    </Typography>
                    <Box
                        component="main"
                        sx={{
                            my: "auto",
                            py: 2,
                            pb: 5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            width: 400,
                            maxWidth: "100%",
                            mx: "auto",
                            borderRadius: "sm",
                            "& form": {
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: "hidden",
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mt: 2 }}>
                            <form
                                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                                    event.preventDefault();
                                    const formElements = event.currentTarget.elements;
                                    login(formElements.username.value, formElements.password.value);
                                }}
                            >
                                <FormControl required>
                                    <FormLabel>Uporabniško ime</FormLabel>
                                    <Input type="username" name="username" />
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Geslo</FormLabel>
                                    <Input type="password" name="password" />
                                </FormControl>
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Button type="submit" fullWidth>
                                        Potrdi
                                    </Button>
                                </Stack>
                                <Button
                                    variant="soft"
                                    onClick={() => {
                                        // props.setIsGuest(true);
                                        // props.setUserEmail("Gost");
                                    }}
                                >
                                    Vstopi kot gost
                                </Button>
                            </form>
                            <Stack gap={4} sx={{ mt: 2 }}>
                                <LoginError loginError={loginError} />
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </Card>
            </CssVarsProvider>
        </>
    );
}