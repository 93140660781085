/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import axios from 'axios';
import { Project, Termin, createTermin, sortTermins } from '../types';
import { isTemplateExpression } from 'typescript';
import { AppContext } from '../App';
import { EditTermin } from './modals/EditTermin';
import { MapsModal } from './modals/MapsModal';
import { Skeleton } from '@mui/joy';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderTable(props: { project: number, addedNew: boolean, snackbarConfig: (snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) => void }) {
  const [descending, setDescending] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [terminEdit, setTerminEdit] = React.useState<Termin>({ id: 1, project: 1, termin_type: "Template", date: "1997-09-09", start: "10:00", end: "11:00", location: "Location" });

  // for google maps
  const [openMaps, setOpenMaps] = React.useState(false);

  const context = React.useContext(AppContext);
  const [termins, setTermins] = React.useState<Termin[]>([{ id: 1, project: 1, termin_type: "Type", date: "Date", start: "Start", end: "End", location: "Location" }]);
  const [displayTermins, setDisplayTermins] = React.useState<Termin[]>([{ id: 1, project: 1, termin_type: "Type", date: "Date", start: "Start", end: "End", location: "Location" }])


  const [projects, setProjects] = React.useState<Project[]>([{id: 1, name: "Avgust 2024", end_date: "2024-08-01"}]);
  const [currProject, setCurrProject] = React.useState<Project>({ id: 1, name: "Avgust 2024", end_date: "2024-08-01" });
  const [loading, setLoading] = React.useState<boolean>(true);


  const [loadTermini, setLoadTermini] = React.useState<boolean>(true);

  async function deleteTermin(terminId: number) {
    await axios.delete(`https://hudniki.pythonanywhere.com/endpoints/termins/${terminId}/`, { headers: { 'Authorization': `Token ${context.token}` } })
    setLoadTermini(!loadTermini);
  }

  React.useEffect(() => {
    if (!editOpen) {
      setLoadTermini(!loadTermini);
    }
  }, [editOpen])



  function RowMenu(props: {termin: Termin}) {
    return (
      <>
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu
        size="sm"
        sx={{ minWidth: 140 }}
        >
          <MenuItem
                disabled={context.token === null}
                onClick={() => {
                  setTerminEdit(props.termin);
                  setEditOpen(true);
                }}
            >Edit</MenuItem>
          {/* <MenuItem>Rename</MenuItem>
          <MenuItem>Move</MenuItem> */}
          <Divider />
            <MenuItem color="danger" disabled={context.token === ""} onClick={() => deleteTermin(props.termin.id)}>Delete</MenuItem>
        </Menu>
      </Dropdown>

      </>
    );
  }

  // Because the EditTermin component is not rerendering when the terminEdit state changes,
  // we need to create a function that rerenders the component
  function RerenderEditTermin() {
    let t = terminEdit;
    React.useEffect(() => { t = terminEdit }, [terminEdit])
    return (< EditTermin termin={t} setTermin={setTerminEdit} open={editOpen} setOpen={setEditOpen} snackbarConfig={props.snackbarConfig} /> );
  }

  React.useEffect(() => {
    if (termins[0].termin_type == "Type")
      {
        getTermini()
      }
  })

  React.useEffect(() => {
    getTermini()
  }, [props.addedNew, loadTermini]);

  async function getTermini() {
     await axios.get(`https://hudniki.pythonanywhere.com/endpoints/termins?project=${props.project}`, {
      // headers: {
      //   'Authorization': `Token ${token}`
      // }
    }).then(response => {
        if (response.data.length === 0) {
           setTermins(termins)
        } else {
        let terminsData: Termin[] = response.data.map((termin: Termin) => {
        // console.log(termin);
        return createTermin(termin.id, termin.project, termin.termin_type, termin.date, termin.start, termin.end, termin.location)
      })
      // console.log("Mapping over termins...")
      terminsData = sortTermins(terminsData);
      setTermins(terminsData);
      setDisplayTermins(terminsData);
      // termins.map((termin) => { console.log(termin) })
      // props.snackbarConfig('success', 'Successfully loaded resource!');
      }
      setLoading(false);
    }).catch(error => {
      console.log(error);
      props.snackbarConfig('danger', 'Error loading resource: ' + error);
      setLoading(true);
    })
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Tip termina</FormLabel>
        <Select
          size="sm"
          placeholder="Filtriraj tip termina"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null,
            ) => {
              if (newValue === "X") {
                setDisplayTermins(termins);
                return;
              }
              let newTermins: Termin[] = termins.filter((termin) => termin.termin_type === newValue)
              setDisplayTermins(newTermins);
            }}
        >
          <Option value="X">Vsi</Option>
          <Option value="V">Vaja</Option>
          <Option value="N">Nastop</Option>
          <Option value="K">Koncert</Option>
        </Select>
      </FormControl>
      {/* <FormControl size="sm">
        <FormLabel>Category</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="refund">Refund</Option>
          <Option value="purchase">Purchase</Option>
          <Option value="debit">Debit</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Customer</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="olivia">Olivia Rhye</Option>
          <Option value="steve">Steve Hampton</Option>
          <Option value="ciaran">Ciaran Murray</Option>
          <Option value="marina">Marina Macdonald</Option>
          <Option value="charles">Charles Fulton</Option>
          <Option value="jay">Jay Hoper</Option>
        </Select>
      </FormControl> */}
    </React.Fragment>
  );

  if (termins.length === 0) return (<></>)
  
  return (
    <React.Fragment>
      {/* <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      > */}
        {/* <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        /> */}
        {/* <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet> */}
      <Box
        // className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        {/* <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for order</FormLabel>
          <Input size="sm" placeholder="Search" startDecorator={<SearchIcon />} />
        </FormControl> */}
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 90, textAlign: 'center', padding: '12px 6px' }}>Tip termina
                {/* <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                /> */}
              </th>
              <th style={{ width: 180, textAlign: 'center', padding: '12px 6px' }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => {
                    setDescending(!descending)
                    setDisplayTermins(sortTermins(displayTermins, descending))
                  }}
                  fontWeight="lg"
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        descending === true ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Datum
                </Link>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>Ura</th>
              <th style={{ width: 240, padding: '12px 6px' }}>Lokacija</th>
            </tr>
          </thead>
          <tbody>
            { displayTermins.map((termin) => (
              <>
                <tr key={termin.id} onMouseEnter={() => { setTerminEdit(termin); }}>
                <td style={{ textAlign: 'center', width: 120 }}>
                  <Chip
                    variant="soft"
                    size="md"
                    
                    color={
                      {
                        V: 'warning',
                        N: 'primary',
                        K: 'success',
                      }[termin.termin_type] as ColorPaletteProp
                    }
                  >
                    {{
                      V: "Vaja",
                      N: "Nastop",
                      K: "Koncert",
                    }[termin.termin_type]}
                  </Chip>
                </td>
                <td style={{textAlign: 'center'}}>
                    <Typography level="body-sm" fontWeight="500" ><Skeleton loading={loading}>{termin.date}</Skeleton></Typography>
                </td>
                
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <div>
                        <Typography level="body-sm"><Skeleton loading={loading}>{termin.start.slice(0,5)} - {termin.end.slice(0,5)}</Skeleton></Typography>
                    </div>
                  </Box>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Link href={`https://maps.google.com/?q=${termin.location.replaceAll(' ', '+')}`} target="_blank" level="body-xs"><Skeleton loading={loading}>
                        {termin.location}</Skeleton>
                      </Link>
                      {/* <Button onClick={() => {setOpenMaps(true)}}>Zemljevid</Button> */}
                      <Dropdown>
                        <MenuButton
                          slots={{ root: IconButton }}
                          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
                          <MoreHorizRoundedIcon />
                        </MenuButton>
                        <Menu size="sm" sx={{ minWidth: 140 }}>
                          <MenuItem
                          disabled={context.token === null}
                            onClick={() => {
                              setTerminEdit(termin);
                              setEditOpen(true);
                            }}
                          >Edit</MenuItem>
                          {/* <MenuItem>Rename</MenuItem>
                          <MenuItem>Move</MenuItem> */}
                          <Divider />
                          <MenuItem color="danger" disabled={context.token === null} onClick={() => deleteTermin(termin.id)}>Delete</MenuItem>
                        </Menu>
                      </Dropdown>

                  </Box>
                </td>
              </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Sheet>
      {/* <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {['1', '2', '3', '…', '8', '9', '10'].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? 'outlined' : 'plain'}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button> */}
        <MapsModal open={openMaps} setOpen={setOpenMaps} />
        <RerenderEditTermin />
      {/* </Box> */}
    </React.Fragment>
  );
}
