import { useContext } from "react";
// import { TokenContext } from "../../App";
import axios from "axios";
import { Button, CssBaseline, CssVarsProvider, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Select, Option, Stack, Alert, ModalClose } from "@mui/joy";
import { Project, Termin, createTermin } from "../../types";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as extendMaterialTheme,
    THEME_ID
} from "@mui/material/styles";

const materialTheme = extendMaterialTheme();

dayjs.extend(customParseFormat)


export function MapsModal(props: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
    // const token = useContext(TokenContext);

    return (
        <>
            <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
                <CssVarsProvider>
                    <CssBaseline />
                    <Modal
                        open={props.open}
                        onClose={() => { props.setOpen(false) }}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <ModalDialog layout='center'>
                        <ModalClose />
                            <DialogTitle>Zemljevid</DialogTitle>
                            <DialogContent>Streliška 18</DialogContent>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2769.1822828709355!2d14.511183676449209!3d46.0474622710895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47652d7bf3dc3873%3A0x2884053cadff34b0!2sStreli%C5%A1ka%20ulica%2018%2C%201000%20Ljubljana%2C%20Slovenia!5e0!3m2!1sen!2snl!4v1706984381780!5m2!1sen!2snl"></iframe>
                        </ModalDialog>
                    </Modal>
                </CssVarsProvider>
            </MaterialCssVarsProvider>
        </>
    );
}