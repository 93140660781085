import * as React from 'react'
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import { AspectRatio, Card, CardActions, CardContent, CardOverflow, Skeleton, Snackbar } from '@mui/joy';
import { Add, Delete } from '@mui/icons-material';
import { useSearchParams } from "react-router-dom";

import axios from 'axios';

import Sidebar from './components/Sidebar';
import OrderTable from './components/OrderTable';
import OrderList from './components/OrderList';
import Header from './components/Header';
import { AddTermin } from './components/modals/AddTermin';
import { Project, createProject } from './types';

import { AppContext } from './App';
import DeleteProject from './components/modals/DeleteProject';
import { toggleSidebar } from './utils';
import MenuIcon from '@mui/icons-material/Menu';


// export const TokenContext = createContext<string | null>(null);

export default function Home() {

  // context with user and token
  const context = React.useContext(AppContext);
  // disabled button state
  const [disabled, setDisabled] = React.useState<boolean>(true);

  // Enable or disable buttons based on whether the context.token is set.
  React.useEffect(() => {
    if (context.token !== null) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [context.token, context.user]);

  // Current project
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentProject, setCurrentProject] = React.useState<Project>({ id: 1, name: "Projekt", end_date: "2024-07-31" });
  const [searchParams] = useSearchParams();
    // project id
  let project_id = Number(searchParams.get("id"));

  // Snackbar states to display error messages, confirmation messages, etc.
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarType, setSnackbarType] = React.useState<"danger" | "neutral" | "primary" | "success" | "warning">("primary");
  const [snackbarInfo, setSnackbarInfo] = React.useState<string>("");

  function snackbarConfig(snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) {
    setSnackbarOpen(true);
    setSnackbarType(snackbarType);
    setSnackbarInfo(snackbarInfo);
  }

  // states for the AddTermin Modal component
  const [openAddTermin, setOpenAddTermin] = React.useState<boolean>(false);

  React.useEffect(() =>
  {
    if (project_id != null) {
    axios.get(`https://hudniki.pythonanywhere.com/endpoints/projects/${project_id}`)
    .then((response) => {
      let projectData = createProject(response.data.id, response.data.name, response.data.end_date);
      setCurrentProject(projectData);
      setLoading(false);
    })
      .catch((error) => console.log(error))
    }
  }, [])

  // open the delete project modal
  const [openDeleteProject, setOpenDeleteProject] = React.useState<boolean>(false);

  return (
    <CssVarsProvider >
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar snackbarConfig={snackbarConfig} />
        <Box
          component="main"
          className="MainContent"
          mb={1}
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="#some-link"
                fontSize={12}
                fontWeight={500}
              >
                Dashboard
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Orders
              </Typography>
            </Breadcrumbs> */}
          </Box>
          {
            project_id !== 0 ? (<>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'row', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          > 
            <Typography level="h2" component="h1">
              <Skeleton loading={loading}>{currentProject.name}</Skeleton>
            </Typography>
            <Button
              disabled={disabled}

              color="primary"
              startDecorator={<Add />}
              size="sm"
              onClick={() => { setOpenAddTermin(true) }}
            >
              Dodaj termin
            </Button>
          </Box>
          {/* Display the termins based on the viewport width */}
            <OrderTable project={Number(searchParams.get("id"))} addedNew={openAddTermin} snackbarConfig={snackbarConfig} />
            <OrderList project={Number(searchParams.get("id") === null ? 0 : searchParams.get("id"))} addedNew={openAddTermin} snackbarConfig={snackbarConfig} />
          <Box
            sx={{
              display: 'flex',
              mt: 3,
              gap: 1,
              flexDirection: { xs: 'row', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'right',
            }}
          >
          <Button
              disabled={disabled}
              color="danger"
              startDecorator={<Delete />}
              size="sm"
              onClick={() => { setOpenDeleteProject(true) }}
            >
              Odstrani projekt
            </Button>
            </Box>
            </>) : (<>
    <Card
      data-resizable
      sx={{
        alignSelf: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '90%',
        // to make the demo resizable
        overflow: 'auto',
        resize: 'horizontal',
        '--icon-size': '30%',
      }}
    >
      <CardOverflow variant="solid" color="warning">
        <AspectRatio
          variant="outlined"
          color="warning"
          ratio="1"
          objectFit='contain'
          sx={{
            m: 'auto',
            transform: 'translateY(50%)',
            borderRadius: '50%',
            width: 'var(--icon-size)',
            boxShadow: 'sm',
            bgcolor: 'background.surface',
            position: 'relative',
          }}
        >
          <div>
            <img src="https://ggs.hudnik.si/images/logo/tranparent_logo_sm.png" />
          </div>
        </AspectRatio>
      </CardOverflow>
      <Typography level="title-lg" sx={{ mt: 'calc(var(--icon-size) / 1.5)' }}>
        Dobrodošli na spletni strani Glasbe gre v svet!
      </Typography>
      <CardContent sx={{ maxWidth: '40ch' }}>
         Izberite projekt v meniju na levi strani.
      </CardContent>
      <CardActions
        orientation="vertical"
        buttonFlex={1}
        sx={{
          '--Button-radius': '40px',
          width: 'clamp(min(100%, 160px), 50%, min(100%, 200px))',
        }}
      >
        <Button variant="solid" color="warning" onClick={() => toggleSidebar()}>
          
        
        <MenuIcon />
        </Button>
      </CardActions>
    </Card>
            </>)
          }
          <Snackbar
            autoHideDuration={3000}
            open={snackbarOpen}
            color={snackbarType}
            onClose={() => {
              setSnackbarOpen(false)
              setSnackbarInfo("")
            }}>
            {snackbarInfo}
          </Snackbar>
          <AddTermin project={currentProject} open={openAddTermin} setOpen={setOpenAddTermin} snackbarConfig={snackbarConfig} />
          <DeleteProject open={openDeleteProject} setOpen={setOpenDeleteProject} project_id={project_id} snackbarConfig={snackbarConfig}/>
        </Box>
      </Box>
    </CssVarsProvider>
  )
  
}
