import React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

import axios from 'axios';

import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import { Project, createProject, sortProjects } from '../types';

// import contexts
import { AppContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { CardCover, Link, Skeleton } from '@mui/joy';
import { Add } from '@mui/icons-material';
import { AddProject } from './modals/AddProject';
// import TransparentLogo from '../../public/transparent_logo_sm.png';

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar(props: { snackbarConfig: (snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) => void }) {

  // setting up the token and user contexts
  const context = React.useContext(AppContext);
  // setting up user state, to rerender on context change
  const [user, setUser] = React.useState<string | null>(context.user);

  // disabled button state
  const [disabled, setDisabled] = React.useState<boolean>(true);

  // Enable or disable buttons based on whether the context.token is set.
  React.useEffect(() => {
    if (context.token !== null) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [context.token, context.user]);

  // setting up the navigation hook
  const navigate = useNavigate();

  // state for all projects
  const [projects, setProjects] = React.useState<Project[]>([{id: 0, name: "projects are not set yet", end_date: "1997-09-09"}])
  const [loading, setLoading] = React.useState<boolean>(true);

  // state for adding a new project
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (projects[0].name == "projects are not set yet") {
      get_projects() 
    }});

  async function get_projects() {
    await axios.get('https://hudniki.pythonanywhere.com/endpoints/projects/', {
        // headers: {
        //   'Authorization': `Token ${token}`
        // }
      }).then(response => {
        console.log(response.data)

        let projectsData: Project[] = response.data.map((project: Project) => {
          // console.log(project);
          return createProject(project.id, project.name, project.end_date)
        })
        // console.log("Mapping over termins...")
        projectsData = sortProjects(projectsData);
        setProjects(projectsData);
        setLoading(false);
        // props.snackbarConfig('success', 'Successfully loaded resource!');
      }).catch(error => {
        console.log(error);
        props.snackbarConfig('danger', 'Error loading resource: ' + error);
        setLoading(true);
      })
  }

  // Check if the right projects were loaded.
  projects.map((project) => { console.log(project) })

  async function logout() {
    if (context.user !== null) {
      await axios.post('https://hudniki.pythonanywhere.com/auth/token/logout', context.token, {
        headers: {
          'Authorization': `Token ${context.token}`
        }})
        .then(response => {
          console.log(response)
          context.setToken(null);
          context.setUser(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        })
        .catch(error => {console.log(error)});
    } else {
      navigate("/login")
    }
    setUser(context.user);
  }

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {/* <IconButton variant="soft" color="primary" size="sm">
          <Avatar
            variant="outlined"
            size="lg"
            src=""
          />
        </IconButton> */}
        <Card component="li" variant="plain" sx={{ minHeight: 150, flexGrow: 1, }}>
          <CardCover>
            <img
              src="https://ggs.hudnik.si/images/logo/tranparent_logo_sm.png"
              // srcSet="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x"
              // loading="lazy"
              alt=""
            />
          </CardCover>
        </Card>
        
      </Box>
      {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography level="title-lg">Glasba gre v svet</Typography>
      </Box> */}
      {/* <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" />  */}
      <Divider />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <Typography
        id="decorated-list-demo"
        level="body-xs"
        textTransform="uppercase"
        fontWeight="lg"
        mb={1}
        mt={1}
      >
          Projekti
        </Typography>
        <List
          size="sm"
          sx={{
            mb: 1,
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {projects.map((project) => {
            return (
          
          <ListItem>
              <ListItemButton component="a" href={`?id=${project.id}`}>
              {/* <HomeRoundedIcon /> */}
              <ListItemContent>
                    <Typography level="title-md"><Skeleton loading={loading}>{project.name}</Skeleton></Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
            )})}
        </List>

        <Button
              disabled={disabled}
              color="primary"
              startDecorator={<Add />}
              size="sm"
              onClick={() => { setOpen(true) }}
            >
              Dodaj projekt
            </Button>
        {/* <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemButton>
              <SupportRoundedIcon />
              Support
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <SettingsRoundedIcon />
              Settings
            </ListItemButton>
          </ListItem>
        </List> */}
        {/* <Card
          invertedColors
          variant="soft"
          color="warning"
          size="sm"
          sx={{ boxShadow: 'none' }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography level="title-sm">Used space</Typography>
            <IconButton size="sm">
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
          <Typography level="body-xs">
            Your team has used 80% of your available space. Need more?
          </Typography>
          <LinearProgress variant="outlined" value={80} determinate sx={{ my: 1 }} />
          <Button size="sm" variant="solid">
            Upgrade plan
          </Button>
        </Card> */}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar
          variant="outlined"
          size="sm"
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{user ? user : "Gost"}</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral"
          onClick={() => {
            logout();
          }}
        >
          {user !== null ? <LogoutRoundedIcon /> : <LoginRoundedIcon /> }
        </IconButton>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
        <AddProject open={open} setOpen={setOpen} snackbarConfig={props.snackbarConfig}/>
      </Box>
    </Sheet>
  );
}
