import { useContext } from "react";
import { AppContext } from "../../App";
import axios from "axios";
import { Button, CssBaseline, CssVarsProvider, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Select, Option, Stack, ModalClose } from "@mui/joy";
import { Project } from "../../types";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as extendMaterialTheme,
    THEME_ID
} from "@mui/material/styles";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";

const materialTheme = extendMaterialTheme();



export function AddTermin(props: { project: Project, open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, snackbarConfig: (snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) => void }) {
    const token = useContext(AppContext).token;

    // States for the Create termin function
    const [loading, setLoading] = React.useState<boolean>(false);
    const [date, setDate] = React.useState<Dayjs | null>(dayjs("2024-09-09"));
    const [end, setEnd] = React.useState<Dayjs | null>(dayjs("2024-09-09"));
    const [location, setLocation] = React.useState<string>("Neznano");
    const [type, setType] = React.useState<string>("V");

    const [stringDate, setStringDate] = React.useState<string>("2024-09-09");
    const [stringStart, setStringStart] = React.useState<string>("HH:mm");
    const [stringEnd, setStringEnd] = React.useState<string>("HH:mm");

    React.useEffect(() => {
        date != null ? setStringDate(date.format("YYYY-MM-DD")) : setStringDate("null");
        date != null ? setStringStart(date.format("HH:mm")) : setStringStart("null");
        end != null ? setStringEnd(end.format("HH:mm")) : setStringEnd("null");
    }, [date, end]);

    // Make a POST request to the API to create a new termin
    
    function addTermin(project: number, termin_type: string, date: string, start: string, end: string, location: string) {
        axios.post('https://hudniki.pythonanywhere.com/endpoints/termins/', {
            'project': project,
            'termin_type': termin_type,
            'date': date,
            'start': start,
            'end': end,
            'location': location
        }, {
            headers: {
                'Authorization': `Token ${token}`
            }
            
        }).then(
            (response) => {
                props.snackbarConfig('success', 'Termin uspešno dodan');
                console.log(response)}
        ).catch(
            (error) => {
                props.snackbarConfig('danger', 'Napaka pri dodajanju termina: ' + error);
                console.log(error)}
        );
    }
  
  
    return (
    <>
            <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
                <CssVarsProvider>
                    <CssBaseline />
        <Modal 
            open={props.open} 
            onClose={() => {props.setOpen(false)}}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Dodaj termin</DialogTitle>
                        <DialogContent>Izpolni vse podatke</DialogContent>
                            <form
                                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                    event.preventDefault();
                                    setLoading(true);
                                    addTermin(props.project.id, type, stringDate, stringStart, stringEnd, location)
                                    props.setOpen(false);
                                    setLoading(false);
                                }}
                            >
                                <Stack spacing={2}>
                                    <FormControl>
                                        <Select 
                                            placeholder="izberi tip termina" 
                                            onChange={(
                                                event: React.SyntheticEvent | null,
                                                newValue: string | null,) => {
                                                    newValue != null ? setType(newValue) : setType("V");
                                            }}>
                                            <Option value="V">Vaja</Option>
                                            <Option value="N">Nastop</Option>
                                            <Option value="K">Koncert</Option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                            <DatePicker 
                                            label="Izberi datum" 
                                            value={date}
                                            onChange={(newValue) => {
                                                setDate(newValue)
                                                // setEnd(newValue)
                                            }}
                                            format="DD.MM.YYYY"
                                            />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimeField
                                                label="Začetek"
                                                value={date}
                                                onChange={(newValue) => setDate((date) => {
                                                    if (date != null && newValue != null) {
                                                        date = date.set('hour', newValue.hour());
                                                        date = date.set('minute', newValue.minute());
                                                        return date
                                                    }
                                                    return date; // Add a default return value
                                                })}
                                                format="HH:mm"
                                            />
                                        </LocalizationProvider>

                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <TimeField
                                                label="Konec"
                                                value={end}
                                                onChange={(newValue) => setEnd((end) => {
                                                    if (end != null && newValue != null) {
                                                        end = end.set('hour', newValue.hour());
                                                        end = end.set('minute', newValue.minute());
                                                        return end
                                                    }
                                                    console.log(end)

                                                    return end; // Add a default return value
                                                })}
                                                format="HH:mm"
                                            />
                                        </LocalizationProvider>

                                    </FormControl>
                            <FormControl>
                                <FormLabel>Lokacija</FormLabel>
                                <Input required onChange={(event) => {setLocation(event.target.value)}} />
                            </FormControl>
                            <Button
                                loading={loading} 
                                type="submit">
                                    Dodaj
                                </Button>
                        </Stack>
                    </form>
                </ModalDialog>
        </Modal>
        </CssVarsProvider>
        </MaterialCssVarsProvider>
    </>
  );
}