import { Button, DialogTitle, Modal, ModalDialog } from "@mui/joy";
import { SECTION_TYPE_GRANULARITY } from "@mui/x-date-pickers/internals/utils/getDefaultReferenceDate";
import axios from "axios";
import { AppContext } from "../../App";
import React from "react";
import { Delete } from "@mui/icons-material";
import { redirect, useNavigate } from "react-router-dom";

export default function DeleteProject(props: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, project_id: number, snackbarConfig: (snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) => void }) {

    const context = React.useContext(AppContext)

    const navigate = useNavigate();

    const deleteProject = () => {
        axios.delete(`https://hudniki.pythonanywhere.com/endpoints/projects/${props.project_id}`, { headers: { 'Authorization': `Token ${context.token}` }})
        .then((response) => {
            console.log(response)
            props.snackbarConfig('success', 'Projekt uspešno odstranjen!');
            props.setOpen(false);
            navigate('/')
        })
        .catch((err) => {
            props.snackbarConfig('danger', 'Napaka pri dodajanju projekta: ' + err);
            console.log(err)
            props.setOpen(false);

        })
    }

    return (
        <>
            <Modal open={props.open}>
                <ModalDialog>
                    <DialogTitle>
                        Želite izbrisati projekt?
                    </DialogTitle>
                    <Button
                        color="danger"
                        startDecorator={<Delete />}
                        size="sm"
                        onClick={() => deleteProject()}
                    >
                        Potrdi
                    </Button>
                </ModalDialog>
            </Modal>
        </>
    )
}