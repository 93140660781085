/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Project, Termin, createTermin, sortTermins } from '../types';
import axios from 'axios';
import { EditTermin } from './modals/EditTermin';

//import contexts for token and user
import { AppContext } from '../App';
import { Skeleton } from '@mui/joy';

export default function OrderList(props: { project: number, addedNew: boolean, snackbarConfig: (snackbarType: 'danger' | 'neutral' | 'primary' | 'success' | 'warning', snackbarInfo: string) => void }) {

    // declare token and user from context
    const context = React.useContext(AppContext);
    // const user = React.useContext(UserContext);

    const [descending, setDescending] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [terminEdit, setTerminEdit] = React.useState<Termin>({ id: 1, project: 1, termin_type: "Template", date: "1997-09-09", start: "10:00", end: "11:00", location: "Location" });

    const [termins, setTermins] = React.useState<Termin[]>([{ id: 1, project: 1, termin_type: "Type", date: "Date", start: "Start", end: "End", location: "Location" }]);
    const [displayTermins, setDisplayTermins] = React.useState<Termin[]>([{ id: 1, project: 1, termin_type: "Type", date: "Date", start: "Start", end: "End", location: "Location" }])


    const [projects, setProjects] = React.useState<Project[]>([{ id: 1, name: "Avgust 2024", end_date: "2024-08-31"}]);
    const [currProject, setCurrProject] = React.useState<Project>({ id: 1, name: "Avgust 2024", end_date: "2024-08-31" });
    const [loading,  setLoading] = React.useState<boolean>(true);

    const [loadTermini, setLoadTermini] = React.useState<boolean>(true);

    async function deleteTermin(terminId: number) {
        await axios.delete(`https://hudniki.pythonanywhere.com/endpoints/termins/${terminId}/`, { headers: { 'Authorization': `Token ${context.token}` } })
        setLoadTermini(!loadTermini);
    }

    React.useEffect(() => {
        if (!editOpen) {
            setLoadTermini(!loadTermini);
        }
    }, [editOpen])

    React.useEffect(() => {
      if (termins[0].termin_type === "Type") {
        getTermini()
      }
    })

    React.useEffect(() => {
      getTermini()
            }, [props.addedNew, loadTermini]
      );

    async function getTermini() {
      await axios.get(`https://hudniki.pythonanywhere.com/endpoints/termins?project=${props.project}`, {
            // headers: {
            //     'Authorization': `Token ${token}`
            // }
        }).then(response => {
          if (response.data.length === 0) {
           setTermins(termins)
        } else {
            let terminsData: Termin[] = response.data.map((termin: Termin) => {
                // console.log(termin);
                return createTermin(termin.id, termin.project, termin.termin_type, termin.date, termin.start, termin.end, termin.location)
            })
            // console.log("Mapping over termins...")
            terminsData = sortTermins(terminsData);
            setTermins(terminsData);
            setDisplayTermins(terminsData);
            // termins.map((termin) => { console.log(termin) })
            // props.snackbarConfig('success', 'Successfully loaded resource!');
          }
            setLoading(false);
        }).catch(error => {
            console.log(error);
            props.snackbarConfig('danger', 'Error loading resource: ' + error);
            setLoading(true);
        })

    }

    function RowMenu(props: { termin: Termin }) {
        return (
            <>
                <Dropdown>
                    <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
                        <MoreHorizRoundedIcon />
                    </MenuButton>
                    <Menu size="sm" sx={{ minWidth: 140 }}>
                        <MenuItem
                            disabled={context.token === null}
                            onClick={() => {
                                setTerminEdit(props.termin);
                                setEditOpen(true);
                            }}
                        >Edit</MenuItem>
                        <Divider />
                        <MenuItem color="danger" disabled={context.token === null} onClick={() => deleteTermin(props.termin.id)}>Delete</MenuItem>
                    </Menu>
                </Dropdown>

            </>
        );
    }

    function RerenderEditTermin() {
        let t = terminEdit;
        React.useEffect(() => { t = terminEdit }, [terminEdit])
        return (< EditTermin termin={t} setTermin={setTerminEdit} open={editOpen} setOpen={setEditOpen} snackbarConfig={props.snackbarConfig} />);
    }

    if (termins.length === 0) return (<></>)
        
    return (
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {termins.map((termin) => (
                <List
                    key={termin.id}
                    size="sm"
                    sx={{
                        '--ListItem-paddingX': 0,
                    }}
                >
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                        }}
                    >
                        <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                            {/* <ListItemDecorator>
                                <Chip
                                    variant="soft"
                                    size="sm"
                                    color={
                                        {
                                            V: 'warning',
                                            N: 'primary',
                                            K: 'success',
                                        }[termin.termin_type] as ColorPaletteProp
                                    }
                                >
                                    {{
                                        V: "Vaja",
                                        N: "Nastop",
                                        K: "Koncert",
                                    }[termin.termin_type]}
                                </Chip>
                            </ListItemDecorator> */}
                            <div>
                                <Typography fontWeight={600} gutterBottom>
                                    <Skeleton loading={loading}>
                                    <Chip
                                        variant="soft"
                                        size="md"
                                        color={
                                            {
                                                V: 'warning',
                                                N: 'primary',
                                                K: 'success',
                                            }[termin.termin_type] as ColorPaletteProp
                                        }
                                    >
                                        {{
                                            V: "Vaja",
                                            N: "Nastop",
                                            K: "Koncert",
                                        }[termin.termin_type]}
                                    </Chip>
                                    </Skeleton>
                                </Typography>
                                {/* <Typography level="body-md" gutterBottom>
                                    {termin.location}
                                </Typography> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'left',
                                        justifyContent: 'left',
                                        gap: 0.5,
                                        mb: 0.5,
                                        mt: 1,
                                    }}
                                >
                                    <Typography level="body-xs"><Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>{termin.date}</Skeleton></Typography>
                                    <Typography level="body-xs"><Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>&bull;</Skeleton></Typography>
                                    <Typography level="body-xs"><Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>{termin.start.slice(0, 5)} - {termin.end.slice(0, 5)}</Skeleton></Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                    <Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}><Link href={`https://maps.google.com/?q=${termin.location.replaceAll(' ', '+')}`} target="_blank" level="body-xs">
                                        {termin.location}
                                    </Link></Skeleton>
                                    <RowMenu termin={termin} />
                                </Box>
                            </div>
                        </ListItemContent>
                        {/* <Chip
                            variant="soft"
                            size="sm"
                            color={
                                {
                                    V: 'warning',
                                    N: 'primary',
                                    K: 'success',
                                }[termin.termin_type] as ColorPaletteProp
                            }
                        >
                            {{
                                V: "Vaja",
                                N: "Nastop",
                                K: "Koncert",
                            }[termin.termin_type]}
                        </Chip> */}
                    </ListItem>
                    <ListDivider />
                </List>
            ))}
            {/* <Box
                className="Pagination-mobile"
                sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
            >
                <IconButton
                    aria-label="previous page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography level="body-sm" mx="auto">
                    Page 1 of 10
                </Typography>
                <IconButton
                    aria-label="next page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <KeyboardArrowRightIcon />
                </IconButton> */}
                <RerenderEditTermin />
            {/* </Box> */}
        </Box>
    );
}
